import React from 'react'
import Helmet from 'react-helmet';
import ContactBar from '../components/ContactBar';
import BookNow from '../components/BookNow';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Emergency from '../components/packages/Emergency';
import Medium from '../components/packages/Medium';
import Longterm from '../components/packages/Longterm';


const KostenPage = ({ location }) => (
  <Layout location={location}>
    <div id="pricing" className="page with-sidebar">
      <Helmet>
        <title>Kosten für Sitzung</title>
        <meta name="description" content="Behandlungsdauer, Kosten und Krankenkassenanerkennung für eine TCM Sitzung in Oerlikon" />
        <meta property="og:title" content="Kosten für eine TCM Sitzung" />
        <meta property="og:description" content="Behandlungsdauer, Kosten und Krankenkassenanerkennung" />
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <div className="content">
          <h1>Kosten</h1>
          <h2>Dauer der Behandlung</h2>
          <ul>
            <li>Erstgespräch mit Behandlung 90 Minuten, jede weitere Akupunktur Behandlung 60 Minuten</li>
            <li>Kräutertherapie – 30 Minuten</li>
            <li>Je nach Bedarf individuell anpassbar</li>
          </ul>
          {/*<h2>Vorteilspakete</h2>
          <div className="packages">
            <Emergency />
            <Medium />
            <Longterm />
            <div className="conditions">* nicht übertragbar, zusätzliche Zeit in längeren Sitzungen oder Kräuterrezepturverschreibungen werden zum üblichen Tarif verrechnet</div>
          </div>*/}
          <h2>Reguläre Kosten</h2>
          <ul>
            <li>pro 60 Minuten Sitzung – sFr. 144.–, längere oder kürzere Sitzungen werden entsprechend angepasst</li>
            <li>Kräuterrezeptur verschreiben 30 Minuten – sFr. 65.-</li>
          </ul>
          <h2>Krankenkassen Anerkennung</h2>
          <ul>
            <li><strong>Ich bin bei allen Zusatzversicherungen für Komplementärmedizin anerkannt.</strong></li>
            <li>SBO-A Mitglied für Akupunktur</li>
            <li>Die Kosten werden bei Vorhandensein einer Zusatzversicherung für Komplementärmedizin anteilsweise übernommen. Bitte informieren Sie sich vor Behandlungsbeginn über Ihre individuellen Konditionen.</li>
          </ul>
        </div>
        <Sidebar />
      </div>
      <BookNow />
      <Contact />
      <Footer />
    </div>
  </Layout>
)

export default KostenPage;


